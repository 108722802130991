import * as React from 'react'
import Layout from '../../layout'
import TermsPage from '../../pagesComponents/login/terms'

const Terms = () => {
	return (
		<Layout title="Terms and Conditions">
			<TermsPage />
		</Layout>
	)
}

export default Terms
